import axios from 'axios'
import loginService from './login.service'
import * as URL from '@/services/url.services'

const apiClient = axios.create({
    baseURL: URL.BACKEND+'/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})

export default {
    getClientes() {
        return apiClient.get('/clientes', {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },

    getClientesById( id ) {
        return apiClient.get(`/clientes/${id}`, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    postCliente(cliente) {
        const clientDto = {
            nome: cliente.nome
        }
        return apiClient.post('/clientes', clientDto, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    getCliente(id) {
        return apiClient.get(`/clientes/${id}`)
    },

    dtoToCliente(clienteDTO) {
        const cliente = {
            id: clienteDTO.id,
            nome: clienteDTO.nome,
            quantidadeUsuarios: clienteDTO.quantidadeUsuarios,
            quantidadeRelatorios: clienteDTO.quantidadeUsuarios,
            usuario: clienteDTO.usuarioMaster.nome,
            emailUsuario: clienteDTO.usuarioMaster.email,
            relatorios: clienteDTO.relatorios
        }
        return cliente
    },

    putCliente(cliente) {
        return apiClient.put(`/clientes/${cliente.id}`, cliente)
    },
    deleteCliente(id) {
        return apiClient.delete(`/clientes/${id}`, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    }

}