<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col v-for="relatorio in getRelatorios" :key="relatorio.id">
          <v-card class="mx-auto" max-width="500">
            <v-card-title class="justify-center" primary-title>{{ relatorio.nome }} </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn tile text :to="('/relatorios/'+relatorio.id)">
                Acessar
                <v-icon left>
                  mdi-eye-arrow-left-outline
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  async created() {
    this.selectRelatorios()
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getRelatorios'])
  },
  methods: {
    ...mapActions(['selectRelatorios'])
  }
};
</script>