import RelatoriosService from '@/services/relatorios.services'
import * as types from '@/store/mutation.types'
import { setInterval } from 'core-js';

const state = {
  relatorios: [],
  relatorio: undefined,
}

const getters = {
  getRelatorios: (state) => {
    return state.relatorios;
  },

  getRelatorio: (state) => {
    return state.relatorio
  },

  getRelatorioNome: (state) => {
    return state.relatorio?.nome
  },

  getObservacoes: (state) => {
    return state.relatorio?.observacoes
  },

  getWidthRelatorio: (state) => {
    return state.relatorio?.width
  },
  
  getHeightRelatorio: (state) => {
    return state.relatorio?.height
  },

  getUrlRelatorio: (state) => {
    return state.relatorio?.url
  },
}

const actions = {

  selectRelatorios: async ({ commit }) => {
    const response = await RelatoriosService.getRelatorios()
    commit(types.SELECT_RELATORIOS, { relatoriosDTO: response });
  },

  selectRelatoriosById: async ({ commit }, id) => {
    const response = await RelatoriosService.getRelatoriosById( id )
    commit(types.PAGE_TITLE, response.nome)
    commit(types.SELECT_RELATORIO, { relatorioDTO: response });
  },

  setObservacoes: async ({commit}, observacoes) => {
    commit(types.SET_OBSERVACOES, {observacoes: observacoes })
  },

  setTamanho: ({commit}, {width, height}) => {
      commit(types.SET_TAMANHO, {width, height})
  }

}

const mutations = {

  [types.SELECT_RELATORIOS]: (state, { relatoriosDTO }) => {
    const relatorios = relatoriosDTO
    state.relatorios = relatorios
  },

  [types.SELECT_RELATORIO]: ( state, { relatorioDTO } ) => {    
    state.relatorio = relatorioDTO
  },

  [types.SET_OBSERVACOES]: ( state, { observacoes } ) => {
    if (state.relatorio) {
      state.relatorio.observacoes = observacoes
    }
  },

  [types.SET_TAMANHO]: (state, {width, height}) => {
    if (state.relatorio) {
      state.relatorio.width = width
      state.relatorio.height = height
    }
  }
  
}

export default {
  state,
  getters,
  mutations,
  actions,
}