<template>
  <div class="mx-16">
    <form>
      <v-text-field v-model="clienteLocal.nome" :error-messages="nomeClienteErrors" label="Nome do cliente" required
        outlined></v-text-field>
      <v-text-field v-model="clienteLocal.usuario" :error-messages="nomeUsuarioErrors" label="Nome do usuário" required
        outlined></v-text-field>
      <v-text-field v-model="clienteLocal.emailUsuario" :error-messages="emailUsuarioErrors" label="E-mail do usuário"
        required outlined></v-text-field>
      <v-text-field v-if="getAcao == 'Criar'" v-model="clienteLocal.senhaUsuario"
        :type="showPassword ? 'text' : 'password'" :error-messages="senhaUsuarioErrors"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" label="Senha"
        required outlined></v-text-field>
      <v-select v-if="getAcao == 'Editar'" v-model="clienteLocal.relatorios" item-text="nome"
        :items="getRelatoriosCliente" label="Relatórios" :menu-props="{ offsetY: true }" required multiple outlined chips
        :append-outer-icon="'mdi-chart-box-plus-outline'" @click:append-outer.stop="dialogRelatorio = true"></v-select>
      <v-file-input v-model="logoFile" label="Selecione a logo para tela de login" accept="image/*">
      </v-file-input>

      <v-toolbar flat>
        <v-btn @click="voltar" class="mr-4"> Voltar </v-btn>
        <v-btn v-if="getAcao == 'Criar'" @click="clear"> Limpar </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" color="primary" @click="handleSave"> Salvar </v-btn>
      </v-toolbar>
    </form>

    <v-dialog no-click-animation persistent v-model="dialogRelatorio" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Cadastrar um relatório
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="relatorioLocal.nome" label="Nome do relatório" required outlined></v-text-field>
          <v-text-field v-model="relatorioLocal.url" label="Endereço do relatório" required outlined></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialogRelatorio = false">
            Cancelar
          </v-btn>

          <v-btn color="green darken-1" text @click="salvarRelatorio">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import s3 from '../aws';
import relatoriosServices from '@/services/relatorios.services';
import { mapActions, mapGetters } from 'vuex';

export default {

  async created() {
    const id = this.$route.params?.id
    if (id && id != 'novo') {
      await this.selectClientesById(id)
      const cliente = await this.getCliente
      this.sincronizar(cliente)
    } else {
      this.clear()
    }
  },

  data: () => ({
    clienteLocal: {
      relatorios: []
    },
    showPassword: false,
    dialogRelatorio: false,

    relatorioLocal: {
      nome: "",
      url: "",
      clienteId: 0
    },

    logoFile: null,
    nomeEmpresa: localStorage.getItem('nomeEmpresa')
  }),

  computed: {
    ...mapGetters(['getCliente', 'getRelatoriosCliente']),

    getAcao() {
      return this.clienteLocal?.id ? 'Editar' : 'Criar'
    },

    nomeClienteErrors() {
      const errors = [];
      return errors;
    },
    nomeUsuarioErrors() {
      const errors = [];
      return errors;
    },
    emailUsuarioErrors() {
      const errors = [];
      return errors;
    },
    senhaUsuarioErrors() {
      const errors = [];
      return errors;
    },
  },

  methods: {

    handleSave() {
      this.submit();
      this.formatarNomeEmpresa();
      console.log(this.clienteLocal.nome);
      console.log(this.nomeEmpresa);
      this.sendImageToAWS();
    },

    sendImageToAWS() {
      const fileExtension = this.logoFile.name.split('.').pop().toLowerCase();
      const contentType = `image/${fileExtension === 'jpg' ? 'jpeg' : fileExtension}`;

      const uploadParams = {
        Bucket: 'banco-imagens',
        Key: `${this.nomeEmpresa}`,
        Body: this.logoFile,
        ACL: 'public-read',
        ContentType: contentType
      }

      s3.putObject(uploadParams, function (err, data) {
        if (err) {
          console.error('Erro ao fazer o upload a imagem.');
        } else {
          console.log('Upload da imagem concluído.', data.location);
        }
      })
    },

    ...mapActions(['insertClientes', 'selectClientesById', 'setMessage']),

    sincronizar(novoCliente) {
      this.clienteLocal = Object.assign({}, novoCliente || { id: 0, nome: '', usuario: '', emailUsuario: '', senhaUsuario: '', relatorios: [] })
    },

    submit() {
      this.insertClientes(this.clienteLocal)
    },

    async salvarRelatorio() {
      this.relatorioLocal.clienteId = this.getCliente.id
      const relatorioInserido = await relatoriosServices.postRelatorio(this.relatorioLocal)
      this.clienteLocal.relatorios.push(relatorioInserido.data)
      this.dialogRelatorio = false
      this.setMessage({ type: 'SUCCESS', message: 'Relatório inserido ao cliente' })
      this.clearRelatorio()
    },

    formatarNomeEmpresa() {
      this.nomeEmpresa = this.clienteLocal.nome.replace(/\s/g, '').toLowerCase();
    },

    voltar() {
      this.$router.push('/clientes')
    },

    clear() {
      this.sincronizar()
    },

    clearRelatorio() {
      this.relatorioLocal = {}
    }

  },

};
</script>