import axios from 'axios'
import CryptoJS from 'crypto-js'
import loginService from './login.service'
import * as URL from '@/services/url.services'

const apiClient = axios.create({
    baseURL: URL.BACKEND+'/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})

export default {

    updateUsuario( usuario ) {
        var usuarioDto = this.editUsuarioToDtoByManager(usuario)
        return apiClient.put(`/usuarios/${usuario.id}`, usuarioDto, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    postUsuario(usuario) {
        var usuarioDto = {}
        if (loginService.getUsuarioRole() === 'ADMIN') {
            usuarioDto = this.usuarioToDtoByAdmin(usuario)
        } else if (loginService.getUsuarioRole() === 'MANAGER') {
            usuarioDto = this.usuarioToDtoByManager(usuario)
        }
        return apiClient.post('/usuarios', usuarioDto, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    getUsuarios() {
        return apiClient.get(`/usuarios`,{
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    deleteUsuarios(id) {
        return apiClient.delete(`/usuarios/${id}`, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    getUsuariosById(id) {
        return apiClient.get(`/usuarios/${id}`, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },
    
    dtoToUsuario(usuarioDTO) {
        const usuario = {
            id: usuarioDTO.id,
            nome: usuarioDTO.nome,
            email: usuarioDTO.email,
            role: this.traduzirPermissao(usuarioDTO.role),
            relatorios: usuarioDTO.relatorios,
            master: usuarioDTO.master,
        }
        return usuario
    },

    usuarioToDtoByAdmin( usuario ) {
        const usuarioDtoByAdmin = {
            nome: usuario.usuario,
            email: usuario.emailUsuario,
            senha: CryptoJS.MD5(usuario.senhaUsuario).toString(),
            clienteId: usuario.clienteId,
        }
        return usuarioDtoByAdmin
    },

    usuarioToDtoByManager( usuario ) {
        const usuarioDtoByManager = {
            nome: usuario.nome,
            email: usuario.email,
            senha: CryptoJS.MD5(usuario.senha).toString(),
            role: this.permissaoToRole(usuario.role),
            relatorios: usuario.relatorios
        }
        return usuarioDtoByManager
    },

    editUsuarioToDtoByManager( usuario ) {
        const relatoriosId = []
        for (const relatorio of usuario.relatorios) {
            if (relatorio.id) {
                relatoriosId.push(relatorio.id)
            } else  {
                relatoriosId.push(relatorio)
            }
        }
        const usuarioDtoByManager = {
            id: usuario.id,
            nome: usuario.nome,
            email: usuario.email,
            role: this.permissaoToRole(usuario.role),
            relatorios: relatoriosId
        }
        return usuarioDtoByManager
    },

    traduzirPermissao(role) {
        if (role == 'ADMIN') {
            return "Administrador"
        } else if (role == 'MANAGER') {
            return "Gestor"
        } else if (role == 'USER') {
            return "Usuário"        
        }
    }, 
    
    permissaoToRole(permissao) {
        if (permissao == 'Gestor') {
            return 'MANAGER'
        } else if (permissao == 'Usuário') {
            return 'USER'
        }
    }, 

    putUsuarios(usuario) {
        return apiClient.put(`/usuarios/${usuario.id}`, usuario)
    },

}