import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginForm from '@/views/LoginForm'

import ClientesForm from '@/views/ClientesForm'
import ClientesList from '@/views/ClientesList'

import UsuariosList from '@/views/UsuariosList'
import UsuariosForm from '@/views/UsuariosForm'

import RelatoriosList from '@/views/RelatoriosList'
import RelatoriosView from '@/views/RelatoriosView'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginForm,
    meta: {
      template: 'Only',
      title: 'Login'
    }
  },
  {
    path: '/:nomedaempresa/login',
    name: 'loginEmpresa',
    component: LoginForm
  },
  {
      path: '/clientes',
      name: 'clientes',
      component: ClientesList,
      meta: {
        title: 'Lista de Clientes'
      }
  },
  {
      path: '/clientes/:id',
      name: 'editarClientes',
      component: ClientesForm,
      meta: {
        title: 'Salvar Cliente'
      }
  },

  {
      path: '/usuarios',
      name: 'usuarios',
      component: UsuariosList,
      meta: {
        title: 'Lista de Usuários'
      }
  },
  {
      path: '/usuarios/:id',
      name: 'editarUsuarios',
      component: UsuariosForm,
      meta: {
        title: 'Salvar Usuário'
      }
  },

  {
    path: '/relatorios/:id',
    name: 'relatoriosById',
    component: RelatoriosView,
    meta: {
      title: 'Relatório'
    }
  },
  {
    path: '/relatorios',
    name: 'relatorios',
    component: RelatoriosList,
    meta: {
      title: 'Lista de Relatórios'
    }
  },
  
 
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `Cockpit - ${to.meta.title}`;
  next();
});

export default router
