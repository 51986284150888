import Router from '@/router/index'
import ClientesService from '@/services/clientes.services'
import UsuarioService from '@/services/usuarios.services'
import * as types from '@/store/mutation.types'

const state = {
  clientes: [],
  cliente: undefined,
}

const getters = {
  getClientes: (state) => {
    return state.clientes;
  },
  getCliente: (state) => {
    return state.cliente
  },
  getRelatoriosCliente: (state) => {
    return state.cliente?.relatorios    
  }
}

const actions = {

  selectClientes: async ({ commit }) => {
    const response = await ClientesService.getClientes()
    commit(types.SELECT_CLIENTES, { clientesDTO: response.data });
  },

  selectClientesById: async ({ commit }, id) => {
    const response = await ClientesService.getClientesById( id )
    commit(types.SELECT_CLIENTE, { clienteDTO: response.data });
  },

  insertClientes: async ({ commit, dispatch }, cliente) => {
    const clienteInserido = await ClientesService.postCliente(cliente)
    cliente.clienteId = clienteInserido.data.id
    await UsuarioService.postUsuario(cliente)
    dispatch('setMessage', {type: 'SUCCESS', message:'Cliente salvo'});
    Router.push(`/clientes`)
  },

  deleteCliente: async ({ commit }, { cliente } ) => {
    const response = await ClientesService.deleteCliente(cliente.id)
    commit(types.DELETE_CLIENTES, cliente )
  },

  selectCliente: ({ commit }, cliente) => {
    commit(types.SELECT_CLIENTE, { clienteDTO: cliente } );
  },
}

const mutations = {
  [types.SELECT_CLIENTES]: (state, { clientesDTO }) => {
    const clientes = []
    for (const clienteDTO of clientesDTO) {
      clientes.push(ClientesService.dtoToCliente(clienteDTO))
    }
    state.clientes = clientes
  },
  [types.SELECT_CLIENTE]: ( state, { clienteDTO } ) => {    
    state.cliente = ClientesService.dtoToCliente(clienteDTO)
  },

  [types.INSERT_CLIENTES]: (state, { cliente }) => {
    state.clientes.push(cliente)
  },

  [types.DELETE_CLIENTES]: (state, cliente ) => {
    const indice = state.clientes.findIndex(cli => cli.id === cliente.id)
    state.clientes.splice(indice, 1)
  },

  /*[types.UPDATE_CLIENTES]: (state, { cliente }) => {
    const indice = state.clientes.findIndex(cli => cli.id === cliente.id)
    state.clientes.splice(indice, 1, cliente)
  },
  */
  
}

export default {
  state,
  getters,
  mutations,
  actions,
}