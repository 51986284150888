import axios from 'axios'
import CryptoJS from 'crypto-js'
import router from '@/router/index'
import * as types from '@/store/mutation.types'
import * as URL from '@/services/url.services'

const apiClient = axios.create({
    baseURL: URL.BACKEND,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})

export default {
    
    async login( {email, senha} ) {
        senha = CryptoJS.MD5(senha).toString()
        const response = await apiClient.post('/login', {email, senha})
        localStorage.setItem(types.LOGIN, JSON.stringify(response.data));
    },
    
    async selectMenus() {
        try {
            const response = await apiClient.get('/api/menus', {
                headers: {
                    'apikey': this.getUsuarioToken()
                }
            })
            localStorage.setItem(types.SELECT_MENUS, JSON.stringify(response.data))
            return this.getMenus()
        } catch (e) {
            router.push('/')
        }
    },

    getUsuario() {
        let usuario = JSON.parse(localStorage.getItem(types.LOGIN));
        return usuario
    },
    getUsuarioNome() {
        const usuario = JSON.parse(localStorage.getItem(types.LOGIN));
        return usuario?.nome
    },
    getUsuarioRole() {
        const usuario = JSON.parse(localStorage.getItem(types.LOGIN));
        return usuario?.role
    },
    getUsuarioToken() {
        const usuario = JSON.parse(localStorage.getItem(types.LOGIN));
        return usuario?.token
    },
    getMenus() {
        const menus = JSON.parse(localStorage.getItem(types.SELECT_MENUS));
        return menus
    },
    
}