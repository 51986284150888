<template>
  <div>
    <v-navigation-drawer app clipped right>
      <v-row align="center" justify="space-around" class="ma-2">
        <h4 color="primary">Observações</h4>
        <v-textarea
          filled
          auto-grow
          rows="6"
          row-height="30"
          :counter="200"
          :rules="[v => (v.length <= 200) || 'A observação deve contar no máximo 200 caracteres']"
          v-model="observacao.texto"
        ></v-textarea>
        <v-btn justify-center @click="submit" color="primary">Salvar</v-btn>
      </v-row>
      <v-row align="center" justify="space-around" class="ma-2">
        <v-card
          v-for="item in this.getObservacoes"
          :key="item.id"
          elevation="8"
          class="mt-4"
        >
          <v-list-item class="grow">
            <v-list-item-content>
              <v-list-item-subtitle class="subheading">{{
                item.anotadoPor
              }}</v-list-item-subtitle>
              <v-list-item-subtitle class="subheading">{{
                item.createdAt
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text primary-title>
            {{ item.texto }}
          </v-card-text>
        </v-card>
      </v-row>
    </v-navigation-drawer>
    <div style="width: 100%; overflow: hidden;">
      <iframe
        :width="this.getWidthRelatorio"
        :height="this.getHeightRelatorio"
        :src="this.getUrlRelatorio"
        style="position: relative;"
        sandbox="allow-same-origin allow-scripts"
      />
    </div>
  </div>
</template>

<script>
import relatoriosServices from "@/services/relatorios.services";
import { mapGetters, mapActions } from "vuex";

export default {

  data: () => ({
    observacao: {
      relatorioId: "",
      texto: "",
    },
  }),

  async created() {
    const id = this.$route.params?.id;
    if (id) {
      this.clearObservacao()
      const rel = await relatoriosServices.getRelatoriosById(id)
      this.setPageTitle(rel.nome)
    } else {
      this.clear();
    }
  },

  methods: {
    ...mapActions(["setObservacoes", "setPageTitle", "setTamanho"]),

    clearObservacao() {
      this.observacao.texto = ""
      this.setObservacoes([])      
    },

    async submit() {
      this.observacao.relatorioId = this.$route.params?.id
      const observacoes = await relatoriosServices.postObservacaoRelatorio(this.observacao)
      this.clearObservacao()
      this.setObservacoes(observacoes.data)
    },
  },
  computed: {
    ...mapGetters(["getRelatorio", 
      "getObservacoes", 
      "getWidthRelatorio", 
      "getHeightRelatorio", 
      "getUrlRelatorio"]),
  },
};
</script>