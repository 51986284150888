import UsuariosService from '@/services/usuarios.services'
import * as types from '@/store/mutation.types'

const state = {
  usuarios: [],
  usuario: undefined,
}

const getters = {
  getUsuarios: (state) => {
    return state.usuarios;
  },

  getUsuario: (state) => {
    return state.usuario
  },
}

const actions = {

  selectUsuarios: async ({ commit }) => {
    const response = await UsuariosService.getUsuarios()
    commit(types.SELECT_USUARIOS, { usuariosDTO: response.data });
  },

  deleteUsuario: async ({ commit }, { usuario } ) => {
    const response = await UsuariosService.deleteUsuarios(usuario.id)
    commit(types.DELETE_USUARIOS, response.data )
  },

  selectUsuariosById: async ({ commit }, id) => {
    const response = await UsuariosService.getUsuariosById( id )
    commit(types.SELECT_USUARIO, { usuarioDTO: response.data });
  },
}

const mutations = {

  [types.SELECT_USUARIOS]: (state, { usuariosDTO }) => {
    const usuarios = []
    for (const usuarioDTO of usuariosDTO) {
      usuarios.push(UsuariosService.dtoToUsuario(usuarioDTO))
    }
    state.usuarios = usuarios
  },
  
  [types.DELETE_USUARIOS]: (state, usuario ) => {
    const indice = state.usuarios.findIndex(usu => usu.id === usuario.id)
    state.usuarios.splice(indice, 1)
  },

  [types.SELECT_USUARIO]: ( state, { usuarioDTO } ) => {    
    state.usuario = UsuariosService.dtoToUsuario(usuarioDTO)
  },

  /*

  [types.INSERT_CLIENTES]: (state, { usuario }) => {
    state.usuarios.push(usuario)
  },
  [types.UPDATE_CLIENTES]: (state, { usuario }) => {
    const indice = state.usuarios.findIndex(cli => cli.id === usuario.id)
    state.usuarios.splice(indice, 1, usuario)
  },
  */
  
}

export default {
  state,
  getters,
  mutations,
  actions,
}