<template>
  <div>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 class="text-xs-center">
          <v-card class="elevation-0">
            <v-img
              class="mx-auto"
              max-height="250"
              max-width="500"
              :src="logoUrl"
              alt="Logo empresa"
            />
            <v-card-text>
              <v-form autocomplete="off">
                <v-text-field
                  v-model="email"
                  type="email"
                  autocomplete="unInput"
                  :error-messages="emailErrors"
                  label="E-mail do usuário"
                  required
                  outlined
                  @input="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                />
                <v-text-field
                  v-model="senha"
                  autocomplete="unInput"
                  :type="showPassword ? 'text' : 'password'"
                  :error-messages="senhaErrors"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  label="Senha"
                  required
                  outlined
                  @input="$v.senha.$touch()"
                  @blur="$v.senha.$touch()"
                />
                <v-btn color="primary" block="block" @click="submit()">
                  Entrar
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      tile
      color="red accent-2"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

import loginService from "@/services/login.service";

export default {
  mixins: [validationMixin],

  data: () => ({
    email: "",
    senha: "",
    showPassword: false,
    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 5000,
    logoUrl: ""
  }),

  async created() {
    const partesUrl = window.location.pathname.split('/');
    let nomeEmpresa = partesUrl[1] || 'selectdata';
    
    localStorage.setItem('nomeEmpresa', nomeEmpresa);
    this.logoUrl = `https://banco-imagens.s3.us-east-2.amazonaws.com/${nomeEmpresa}`;
  },

  validations: {
    email: { required, email },
    senha: { required, minLength: minLength(6) },
  },

  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        loginService
          .login({ email: this.email, senha: this.senha })
          .then(() => {
            if (loginService.getUsuarioRole() == "ADMIN") {
              this.$router.push("/clientes");
            } else if (loginService.getUsuarioRole() == "MANAGER") {
              this.$router.push("/usuarios");
            } else if (loginService.getUsuarioRole() == "USER") {
              this.$router.push("/relatorios");
            } else {
              this.snackbarText =
                "Não possui permissões no usuário que está tentando fazer login";
              this.snackbar = true;
            }
          })
          .catch((error) => {
            console.error(error);
            if (error.request.status) {
              this.snackbarText = "Falha na autenticação";
              this.snackbar = true;
            }
          });
      }
    },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Informe um e-mail válido");
      !this.$v.email.required && errors.push("O e-mail é obrigatório");
      return errors;
    },
    senhaErrors() {
      const errors = [];
      if (!this.$v.senha.$dirty) return errors;
      !this.$v.senha.minLength &&
        errors.push("A senha precisa de pelo menos 6 dígitos");
      !this.$v.senha.required && errors.push("A senha é obrigatória");
      return errors;
    },
  },
};
</script>