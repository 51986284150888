<template>
    <div class="mx-16">

        <v-data-table
          :headers="headers"
          :items="getClientes"
          :search="search"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-text-field
                label="Pesquisa"
                outlined
                v-model="search"
                append-icon="mdi-magnify"
                hide-details
                dense
              ></v-text-field>

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                class="mb-2"
                to="/clientes/novo"
              >
                Novo cliente
              </v-btn>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Remover o cliente {{ clienteParaRemover?.nome || '' }} ?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                    <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn
              @click="selectClientes"
              color="primary">
              Buscar os dados novamente
            </v-btn>
          </template>

        </v-data-table>
  </div>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex'

  export default {
    data: () => ({
      search: '',
      dialogDelete: false,
      clienteParaRemover: undefined,
      headers: [
        { text: 'Nome do cliente', align: 'start', sortable: true, value: 'nome' },
        { text: 'Responsável', value: 'usuario' },
        //{ text: 'Usuários', value: 'quantidadeUsuarios' },
        { text: 'Relatórios', value: 'relatorios.length' },
        { text: 'Ações', value: 'actions', sortable: false }
      ],
    }),

    async created () {
      await this.selectClientes();
    },

    computed: {
      ...mapGetters(['getClientes']),
    },

    methods: {
      ...mapActions(['selectClientes', 'deleteCliente']),

      editItem (item) {
        this.$router.push(`/clientes/${item.id}`)
      },

      deleteItem (item) {
        this.dialogDelete = true
        this.clienteParaRemover = item
      },

      deleteItemConfirm () {
        this.deleteCliente({cliente: this.clienteParaRemover})        
        this.closeDelete()
      },

      closeDelete() {
        this.dialogDelete = false
        this.clienteParaRemover = undefined
      },

    },
  }
</script>