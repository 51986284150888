import LoginService from '@/services/login.service'
import * as types from '@/store/mutation.types'
import router from '@/router/index'

const state = {
}

const getters = {
}

const actions = {
  logout: ({ commit }) => {
    localStorage.setItem(types.LOGIN, null);
    localStorage.setItem(types.SELECT_MENUS, null);
    router.push('/')
  }
}

const mutations = {
}

export default {
  state,
  getters,
  mutations,
  actions,
}