import axios from 'axios'
import loginService from './login.service'
import * as URL from '@/services/url.services'

const apiClient = axios.create({
    baseURL: URL.BACKEND+'/api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
})

export default { 

    async getRelatoriosById(relatoriosId) {
        const result = await apiClient.get(`/relatorios/${relatoriosId}`,{
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
        const rel = result.data
        const {width, height} = this.adaptarTamanho()
        rel.width = width
        rel.height = height
        return rel
    },

    async getRelatoriosByCliente() {
        const result = await apiClient.get('/relatoriosByClientes',{
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
        return result.data
    },
    
    async getRelatorios() {
        const result = await apiClient.get('/relatorios',{
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
        return result.data
    },

    postRelatorio( {nome, url, clienteId }) {
        const relatorioDto = {
            nome,
            url,
            clienteId
        }
        return apiClient.post('/relatorios', relatorioDto, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },

    postObservacaoRelatorio(observacao) {
        return apiClient.post('/observacoes-relatorios', observacao, {
            headers: {
                'apikey': loginService.getUsuarioToken()
            }
        })
    },

    adaptarTamanho() {
        var html = document.documentElement;
        var height = html.clientHeight - 140;
        return {
          width: '100%',
          height: height+'px'
        }
      },

}