import Vue from 'vue'
import Vuex from 'vuex'

import { PAGE_TITLE, MESSAGE_PAGE } from '@/store/mutation.types'
import loginModule from '@/store/modules/login.store'
import clientesModule from '@/store/modules/clientesStore'
import usuariosModule from '@/store/modules/usuariosStore'
import relatoriosModule from '@/store/modules/relatoriosStore'

Vue.use(Vuex)

const modules = {
  login: loginModule,
  clientes: clientesModule,
  usuarios: usuariosModule,
  relatorios: relatoriosModule
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules,
  state: {
    pageTitle: '',

    snackbar: false,
    snackbarText: "",
    snackbarColor: ""
  },
  mutations: {
    PAGE_TITLE(state, title) {
      state.pageTitle = title
    },
    MESSAGE_PAGE(state, {active = true, color, message}) {
      state.snackbarText = message
      state.snackbarColor = color
      state.snackbar = active
    }
  },
  actions: {
    setPageTitle({commit}, title) {
      commit(PAGE_TITLE, title)
    },
    setMessage({commit}, snackbar) {
      if (snackbar.type == 'ERROR') {
        snackbar.color = 'red accent-2'
        setTimeout(function(){commit(MESSAGE_PAGE, {active: false, message: "", color: ""})}, 8000)
        snackbar.timeout = 8000
      } else if(snackbar.type == 'SUCCESS') {
        snackbar.color = 'green'        
        setTimeout(function(){commit(MESSAGE_PAGE, {active: false, message: "", color: ""})}, 4000)
      } else {
        snackbar.color = 'gray accent-2'
        setTimeout(function(){commit(MESSAGE_PAGE, {active: false, message: "", color: ""})}, 5000)
      }
      commit(MESSAGE_PAGE, snackbar)
    },
  },
  getters: {
    getPageTitle: function (state) {
      return state.pageTitle
    },
    getSnackbarMessage: function (state) {
      return state.snackbarText
    },
    getSnackbarColor: function (state) {
      return state.snackbarColor
    },
    getSnackbarActive: function (state) {
      return state.snackbar
    }
  }
})
