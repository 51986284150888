<template>
  <div class="mx-16">
    <form>
      <div v-if="usuarioLocal.master && getAcao == 'Editar'"
          class="pb-6">
        <span>
          Não é permitido alterar a permissão do usuário principal
        </span>
      </div>

      <v-text-field
        v-model="usuarioLocal.nome"
        :error-messages="nomeErrors"
        label="Nome do usuário"
        required
        outlined
      ></v-text-field>
      <v-text-field
        v-model="usuarioLocal.email"
        :error-messages="emailErrors"
        label="E-mail do usuário"
        required
        outlined
      ></v-text-field>
      <v-text-field
        v-if="getAcao == 'Criar'"
        v-model="usuarioLocal.senha"
        :type="showPassword ? 'text' : 'password'"
        :error-messages="senhaErrors"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        label="Senha"
        required
        outlined
      ></v-text-field>
      <v-select
        v-if="!usuarioLocal.master"
        v-model="usuarioLocal.role"
        :items="getTodasPermissoes"
        item-text="nome"
        item-value="valor"
        label="Permissão"
        :menu-props="{ offsetY: true }"
        required
        outlined
        chips
      ></v-select>
      <v-select
        v-model="usuarioLocal.relatorios"
        :items="getTodosRelatorios"
        item-text="nome"
        item-value="id"
        label="Relatórios"
        :menu-props="{ offsetY: true }"
        required
        multiple
        outlined
        chips
      ></v-select>

      <v-toolbar flat>
        <v-btn @click="voltar" class="mr-4"> Voltar </v-btn>
        <v-btn @click="clear"> Limpar </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="mr-4" color="primary" @click="submit"> Salvar </v-btn>
      </v-toolbar>
      
    </form>
  </div>
</template>

<script>
import relatoriosServices from '@/services/relatorios.services';
import usuariosServices from '@/services/usuarios.services';

import { mapActions, mapGetters } from 'vuex';

export default {

  async created() {
    this.todasPermissoes = [
      {nome: 'Gestor', valor: 'Gestor'},
      {nome: 'Usuário', valor: 'Usuário'}
    ]

    this.selectRelatoriosCliente()

    const id = this.$route.params?.id
    if (id && id != 'novo') {
      await this.selectUsuariosById(id)
      const usuario = await this.getUsuario
      this.sincronizar(usuario)
    } else {
      this.clear()
    }
  },

  data: () => ({
    usuarioLocal: {},
    todasPermissoes: [],
    todosRelatoriosCliente: [],
    showPassword: false,
  }),

  computed: {
    ...mapGetters(['getUsuario']),

    getAcao() {
      return this.usuarioLocal?.id ? 'Editar' : 'Criar'
    },

    getTodasPermissoes() {
      return this.todasPermissoes
    },

    getTodosRelatorios() {
      return this.todosRelatoriosCliente
    },

    getUsuarioLocal() {
      return this.usuarioLocal
    },

    nomeErrors() {
      const errors = [];
      return errors;
    },
    emailErrors() {
      const errors = [];
      return errors;
    },
    senhaErrors() {
      const errors = [];
      return errors;
    },
  },

  methods: {
    ...mapActions(['insertUsuarios', 'selectUsuariosById', 'setMessage']),

    async selectRelatoriosCliente() {
      this.todosRelatoriosCliente = await relatoriosServices.getRelatoriosByCliente()
    },

    sincronizar(novoUsuario) {
      this.usuarioLocal = Object.assign({}, novoUsuario || {id: 0, nome:'', email: '', senha:'', role: '', relatorios:[]})
    },

    async submit() {
      if (this.getUsuarioLocal.id) {
        const usuarioAlterado = await usuariosServices.updateUsuario(this.getUsuarioLocal)
        this.setMessage({type: 'SUCCESS', message:'Alteração efetuada com sucesso'})
      } else {
        const usuarioInserido = await usuariosServices.postUsuario(this.getUsuarioLocal)
        this.setMessage({type: 'SUCCESS', message:'Usuário inserido com sucesso'})
      }
      this.clear()
      this.voltar()
    },

    voltar() {
      this.$router.push('/usuarios')
    },

    clear() {
      this.sincronizar()
    },
  
  },
};
</script>