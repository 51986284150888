<template>
  <div>
    <v-app-bar class="flex-grow-0 elevation-0" app clipped-right>
      <v-toolbar-title>{{ this.getPageTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <p>{{ this.usuarioNome }}<small><span>{{this.getPermissao}}</span></small></p>
    </v-app-bar>
    <v-navigation-drawer
      app
      permanent
      :mini-variant="mini"
      color="primary"
      :mini-variant-width="75"
    >
      <v-divider></v-divider>
      <v-list dense nav>

        <v-divider></v-divider>

        <div v-for="item in getMenus" :key="item.id">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item :to="item.to" link v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon color="white">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <v-list-group
            dense
            v-for="submenu in item.children"
            :key="submenu.id"
          >
            <template v-slot:activator>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item :to="'/relatorios/'+submenu.id" link v-bind="attrs" v-on="on">
                    <v-list-item-title class="submenu">{{
                      submenu.title | maxLengthSubmenu
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ submenu.title }}</span>
              </v-tooltip>
            </template>
          </v-list-group>
        </div>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="white">
                      mdi-arrow-collapse-left
                    </v-icon>
                  </template>
                  <span>Sair</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </template>
    </v-navigation-drawer>
    <slot />
    <v-snackbar
      v-model="this.getSnackbarActive"
      :color="this.getSnackbarColor"
      tile
    >
      {{ this.getSnackbarMessage }}

    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import loginService from "@/services/login.service";
import relatorioService from "@/services/relatorios.services";

export default {
  data() {
    return {
      usuarioNome: "",
      permissao: "",
      menus: [],
      drawer: true,
      logoUrl: ""
    };
  },

  async created() {
    const nomeEmpresa = localStorage.getItem('nomeEmpresa');
    const extensions = ['.png', '.jpg', '.jpeg'];

    let logoPath = null
    for (const ext of extensions) {
      const url = `/logos/${nomeEmpresa}/logo${ext}`;

      try {
        await axios.head(url);
        logoPath = url;
        break;
      } catch (error) {
        continue;
      }
    }

    this.logoUrl = logoPath;
  },

  async mounted() {
    if (!loginService.getUsuario()) {
      this.$router.push("/");
    } else {
      this.title = this.$route.meta.title
      this.usuarioNome = loginService.getUsuarioNome()
      this.permissao = loginService.getUsuarioRole()
      const menus = await loginService.selectMenus()
      this.menus = menus
    }
  },
  
  watch: {
    $route(to, from) {
      if (to.name == 'relatoriosById') {
        const relatorioId = to.params.id
        this.selectRelatoriosById(relatorioId)
        this.setTamanho(relatorioService.adaptarTamanho())
      } else {
        this.setPageTitle(this.$route.meta.title)
      }
    },
  },

  methods: {
    ...mapActions(["setPageTitle", "logout", "selectRelatoriosById", "setTamanho"]),
  },

  filters: {
    maxLengthSubmenu: function (value) {
      return value.substr(0, 10);
    },
  },

  computed: {

    ...mapGetters(['getRelatorio', 'getPageTitle', 'getSnackbarMessage', 'getSnackbarColor', 'getSnackbarActive']),

    getMenus() {
      return this.menus;
    },
    getPermissao() {
      if (this.permissao == 'ADMIN') {
        return " - Administrador"
      } else if (this.permissao == 'MANAGER') {
        return " - Gestor"
      } else if (this.permissao == 'USER') {
        return " - Usuário"        
      }
    },
    getTitle() {
      return this.title || this.getRelatorioNome
    },
    mini() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return true
        case 'md': return true
        case 'lg': return true
        case 'xl': return true
      }
    },
  }
};
</script>

<style scoped>
.v-list-item--active {
  background-color: #7D7D7D;
  color: white;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-list-item__icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.title {
  flex-grow: 10;
  color: var(--text-reverse);
  text-overflow: show;
}

.submenu {
  color: white;
  font-size: 0.8rem !important;
  width: 64px;
  padding: 8px 3px;
  transition: opacity 1s ease;
  opacity: 0.9;
}
.submenu:active {
  color: #50af31;
}
</style>