export const PAGE_TITLE = 'PAGE_TITLE'
export const MESSAGE_PAGE = 'MESSAGE_PAGE'

export const LOGIN = 'LOGIN'
export const SELECT_MENUS = 'SELECT_MENUS'
export const LOGOUT = 'LOGOUT'

export const SELECT_CLIENTES = 'SELECT_CLIENTES'
export const SELECT_CLIENTE = 'SELECT_CLIENTE'
export const INSERT_CLIENTES = 'INSERT_CLIENTES'
export const DELETE_CLIENTES = 'DELETE_CLIENTES'

export const SELECT_USUARIOS = 'SELECT_USUARIOS'
export const DELETE_USUARIOS = 'DELETE_USUARIOS'
export const SELECT_USUARIO = 'SELECT_USUARIO'

export const SELECT_RELATORIOS = 'SELECT_RELATORIOS'
export const SELECT_RELATORIO = 'SELECT_RELATORIO'
export const SET_OBSERVACOES = 'SET_OBSERVACOES'
export const SET_TAMANHO = 'SET_TAMANHO'


//Não estão sendo usados ainda
export const UPDATE_CLIENTES = 'UPDATE_CLIENTES'