<template>
  <v-app id="inspire">
    <component :is="getTemplate">
      <v-main>
        <router-view class="pa-4"></router-view>
      </v-main>
    </component>
  </v-app>
</template>

<script>

  import Default from '@/components/Default.vue'
  import Only from '@/components/Only.vue'

  export default {
    computed: {
      getTemplate() {
        return this.$route?.meta?.template || 'Default'
      }
    },
    components: {
      Default,
      Only
    }
  }
</script>